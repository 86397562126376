import React from 'react';
// import AudioPlayer from "react-h5-audio-player";
import Img from "gatsby-image"
import { Link } from "gatsby"
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import './style.scss';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage";
import Wrapper from '../Wrapper/Wrapper';
import SEO from '../../components/seo';
import Iff from '../../components/Iff';
import DonationsForm from '../../components/Forms/DonationsForm/DonationsForm';
import Columns from '../../components/Columns/Columns';
import {htmlDecode} from "../../helpers"


const CommunityBlogPost = ({pageContext}) => {
    const donationsForm = pageContext.acf.include_donation_form ? <DonationsForm campaign={pageContext.acf.campaign_code} domain="www.flipcause.com" /> : null;
    console.log(pageContext);
    return (
    <Wrapper pageCurrent={ `community-blog ${pageContext.title.toLowerCase()}` }>
        <SEO title={pageContext.title} />
        <div className="blockwrap menu-spacer">
            <FeaturedImage pageContext={pageContext} 
                mobile={pageContext.featured_media.mobile_ft.childImageSharp.fluid}
                desktop={pageContext.acf.desktop_featured_image.localFile.childImageSharp.fluid} 
            /> 
        </div>
        <div className="blockwrap">
            
            <div className="container base-styles">
            
                <div className="content">
                    <div className="inner-heading-wrap">
                        <h1 className="animate-underline" dangerouslySetInnerHTML={{__html: pageContext.title }}></h1>
                    </div>
                    <h3 class="author-credits">{pageContext.author.name}, {pageContext.date}</h3>
                    <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                    <div className="audio-player-wrap">
                        <Iff condition={pageContext.acf.podcast_url}>
                            <AudioPlayer
                                src={pageContext.acf.podcast_url}
                                onPlay={e => console.log("onPlay")}
                                // other props here
                            />
                        </Iff>
                    </div>
                </div>
                
            </div>
           
        </div>
        {donationsForm}
        <div className="blockwrap">
            <div className="base-styles related-posts">
                <div className="content">
                    <h2 className="animate-underline">Related Blog Posts</h2>

                    <Columns className="four">
                        {
                            pageContext.relatedArticles.map(a=>{
                                return <Link to={`/resources/community-blog/${a.slug}`} className="related-post" key={"ra-" + a.slug}>
                                    <div className="ft-img-wrap">
                                        <Img fluid={a.s} alt={"Related: " + htmlDecode(a.title)} />
                                    </div>
                                    <h3>{htmlDecode(a.title)}</h3>
                                    <h4>{a.author.name}, {a.date.replace(',', '')}</h4>
                                </Link> 
                            })
                        }
                    </Columns>
                </div>
            </div>
        </div>
    </Wrapper>

)

}
export default CommunityBlogPost;